import React, { useState, useRef, useEffect } from 'react'
import { Button, Divider, Input, Space, Col, notification, Row, Select, Modal, Form } from 'antd'
import ImageRocket from './../../Assets/img/FileUpload/cohete.gif'
import {
	PlusCircleTwoTone,
	CloseCircleTwoTone,
	ClockCircleOutlined,
	PlusOutlined
} from '@ant-design/icons'
import './../../Styles/Components/FileUpload/CardFileUpload.css'
import { useDispatch, useSelector } from 'react-redux'
import { addInputVersionBaseline, storeInputVersionBaseline, storeVersionSelectedBaseline } from '../../Redux/Reducers/Baseline'
import { newDataForecast } from '../../Redux/Reducers/Fileupload'

let index = 0;

const CardFileUpload = ({
	nameFile,
	onAction,
	onDownloadTemplates,
	nameFileTemplate,
	isLoading,
	isSuccess,
}) => {
	const { 
		rex_data_forecast,
		rex_uploading_file
	} = useSelector(state => state.fileUpload)

	const [isModalOpen, SetIsModalOpen] = useState(false)
	const [fileNameTag, setFileNameTag] = useState(null)
    const [fileUrl, setFileUrl] = useState(null)
	const dispatch = useDispatch()

	const inputRef = useRef(null)
    const [typeFile, setTypeFile] = useState(null)
	const [valueForecast, setValueForecast] = useState(null)
    // const [textForecast, setTextForecast] = useState(null)

	const [api, contextHolder] = notification.useNotification()

	const openNotificationWithIcon = (description) => {
		api['warning']({
		  message: 'Alerta',
		  description: description
		})
	}

	const [name, setName] = useState('')
	const [items, setItems] = useState(['jack', 'lucy']);

	const { 
        rex_names_version_baseline,
		rex_input_version_baseline,
		rex_version_selected_baseline
	} = useSelector(state => state.baseline)

	const onNameChange = (event) => {
		setName(event.target.value)
	}


	const RefFile = useRef(null)

	const imageFileUpload = async (image) => {
        if(image){
            await setFileUrl([image])
            await setFileNameTag(image.name)
			if(nameFile == 'Plantilla baseline'){
				SetIsModalOpen(true)
			}
        }
    }
	
	const validateFile = async () => {
		if(fileUrl){
			if(nameFile == "Plantilla forecast"){
				await onAction(fileUrl, valueForecast)
			}else{
				await onAction(fileUrl)
			}
		}
	}

	const addItem = (e) => {

		if(name == ""){
			openNotificationWithIcon('El nombre del baseline es requerido')
			return
		}

		let findNameExisting = rex_names_version_baseline.find(nv => nv.value == name.toUpperCase())

		if(findNameExisting){
			openNotificationWithIcon(`Ya existe un versionamiento con el nombre ${name}`)
			return
		}

		dispatch(addInputVersionBaseline(name))
		e.preventDefault()
		setName('');
		setTimeout(() => {
			inputRef.current?.focus()
		}, 0)
	
		return
	}


	const removeFileUpload = () => {
		setFileNameTag(null)
		setFileUrl(null)
		setValueForecast(null)
	}

	const downloadTemplate = (name_template) => {
		onDownloadTemplates(name_template)
	}

	useEffect(() => {

	},[rex_names_version_baseline])

	// useEffect(() => {
		
	// },[rex_uploading_file])

	return (
		<Row className='CardFileUpload'>
			{contextHolder}
			<Col span={24} className='CardFileUpload__Info'>
				<img
					src={ImageRocket}
				/>
				<input 
					type="file" 
					ref={RefFile} 
					style={{display: 'none'}} 
					onChange={(e) => {
						imageFileUpload(e.target.files[0], e)
						e.target.value = null
					}} 
				/>
				<div style={{width:'100%', alignItems:'center', textAlign:'center', display:'flex',  justifyContent:'normal', gap:'5px'}}>
					<div>
						{
							isLoading
							?	<ClockCircleOutlined
									style={{fontSize:'20px'}}
									twoToneColor='#1677ff'
								/>
							:	<PlusCircleTwoTone
									disabled
									style={{
										fontSize:'20px', 
										cursor:'pointer',
									}}
									twoToneColor='#1677ff'
									onClick={() => {
										if(!rex_uploading_file){
											RefFile.current.click()
										}
									}}
								/>
						}
					</div>
					<div style={{display:'flex', width:'100%', textOverflow:'ellipsis', overflow:'hidden'}}>
						{nameFile}
					</div>
				</div>
			</Col>
			{
				!fileNameTag
				? null
				: fileNameTag
					? <Col span={24} style={{marginTop: '5px'}}>
						<Row>
							<Col span={20}>
								<div style={{fontSize:'12px', textOverflow:'ellipsis', textWrap:'nowrap', overflow:'hidden'}}>{fileNameTag}</div>
							</Col>
							<Col span={4} style={{display:'flex', justifyContent:'end'}}>
								<div>
									<CloseCircleTwoTone
										onClick={removeFileUpload}
										style={{
											cursor:'pointer',
											fontSize:'16px'
										}}
										twoToneColor='#ff4d4f'
									/>
								</div>
							</Col>
						</Row>
						{
							nameFile == "Plantilla forecast"
							?	<Row style={{marginTop: '5px', marginBottom: '5px'}}>
									<Col span={24}>
										<Select
											placeholder="Seleccione un forecast"
											allowClear
											options={rex_data_forecast}
											dropdownRender={(menu) => (
												<>
													<Space style={{ padding: '4px 8px 0' }} >
														<Input
															placeholder="Agregar nuevo forecast"
															onPressEnter={(e) => {
																const data_forecast = []
																if(e.target.value){
																	if(!rex_data_forecast.find(df => df.value == e.target.value)){
																		rex_data_forecast.map(df => data_forecast.push(df))
																		data_forecast.push({ label: e.target.value, value: e.target.value })
																		dispatch(newDataForecast(data_forecast))
																	}
																}
																e.stopPropagation()
															}}
															size='small'
														/>
													</Space>
													<Divider style={{ margin: '8px 0' }} />
													{menu}
												</>
											)}
											size='small'
											style={{width: '180px'}}
											onChange={(e, opt) => {
												if(opt){
													setValueForecast({value: opt.value, text: opt.label})
												}else{
													setValueForecast(null)
												}
											}}
										/>
									</Col>
								</Row>
							: 	null
						}
					</Col>
					: null
			}
			<Col span={24} className='CardFileUpload__Actions'>
			{
				nameFile == "Plantilla forecast"
				?	<Button
						type='primary'
						onClick={validateFile}
						className='CardFileUpload__Button'
						disabled={
							rex_uploading_file
							? true 
							: !valueForecast 
								? true
								: false
						}
						loading={isLoading}
					>
						{isLoading ? 'Subiendo' : 'Subir'}
					</Button>
				:	<Button
						type='primary'
						onClick={validateFile}
						className='CardFileUpload__Button'
						disabled={
							rex_uploading_file
							? true
							: !fileUrl
								? true
								: false
						}
						loading={isLoading}
					>
						{isLoading ? 'Subiendo' : 'Subir'}
					</Button>
			}
				<Button 
					size='small'
					className='Download__Template'
					onClick={() => downloadTemplate(nameFileTemplate)}
				>
					Descargar {nameFile}
				</Button>
			</Col>
			<Modal 
				title={null}
				closeIcon={null}
				open={isModalOpen} 
				// onOk={handleOk} 
				footer={[
					<>
						<Button
							onClick={() => {
								SetIsModalOpen(false)
								setFileNameTag(null)
								setFileUrl(null)
								setTypeFile(null)
							}}
							danger
						>Cancelar</Button>
						<Button
							type='primary'
							disabled={rex_uploading_file}
							onClick={() => {
								validateFile()
								SetIsModalOpen(false)
							}}
						>Aceptar</Button>
					</>
				]}
			>
				<Row
					style={{width:'100%'}}
				>
					<Form 
						layout='vertical'
						style={{
							width:'100%',
							display:'flex'
						}}
					>
						<Col span={12}>
							<Form.Item label="Nombre baseline:">
								<Select
									value={rex_version_selected_baseline.name}
									onChange={(e) => {
										let nameSelected = rex_names_version_baseline.find(nv => nv.label == e)
										dispatch(storeVersionSelectedBaseline({type: 'name', name : e, version:null}))
										dispatch(storeInputVersionBaseline(nameSelected['versions']))
									}}								
									placeholder="Nombre"
									style={{width: '200px'}}
									dropdownRender={(menu) => (
										<>
											{menu}
											<Divider
												style={{
												margin: '8px 0',
												}}
											/>
											<Space
												style={{
												padding: '0 8px 4px',
												}}
											>
											<Input
												placeholder="Nueva versión"
												ref={inputRef}
												value={name}
												onChange={onNameChange}
												onKeyDown={(e) => e.stopPropagation()}
											/>
											<Button type="text" icon={<PlusOutlined />} onClick={addItem}>
											</Button>
										  </Space>
										</>
									)}
									options={rex_names_version_baseline}
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label="Versión:">
								<Select
									value={rex_version_selected_baseline.version}
									placeholder="Versión"
									style={{width: '200px'}}
									onChange={(e) => {
										dispatch(storeVersionSelectedBaseline({type: 'version', name : null, version:e}))
									}}
									options={rex_input_version_baseline}
								/>
							</Form.Item>
						</Col>
					</Form>
				</Row>

			</Modal>
		</Row>
	)
}

export default CardFileUpload