import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useQuery } from '@tanstack/react-query'
import { Col, Row } from 'antd'
import { getDashboardServices } from '../../Redux/Services/Administrator/Dashboard'

const Deck = () => {
    const { 
        rex_data_dashboard,
	} = useSelector(state => state.dashboard)

    const dispatch = useDispatch()

    const dashboardData = useQuery({
		queryKey: ['dashboard'],
		queryFn : () => getDashboardServices(dispatch),
		retry: 1,
		refetchOnWindowFocus : false,
	})

    return (
        <Row style={{padding: '25px 20px', width:'100%'}}>
            <Col span={24}>
                DECK
            </Col>
            <Col span={24}>
                {
                    dashboardData.isLoading
                    ?   <div style={{marginTop: '10px'}}>Cargando deck</div>
                    :   rex_data_dashboard && rex_data_dashboard.dasvisualizacion
                        ?   <iframe
                                width="100%"
                                height="900"
                                style={{marginTop: '10px'}}
                                src={rex_data_dashboard.dasurl}
                            />
                        :   null
                }
            </Col>
        </Row>
    )
}

export default Deck