import config from '../../config'
import { notification } from 'antd'
import { storeDataUser } from '../Reducers/User'

const notificacionServidor = (type, mensaje) => {
    if(type === 'success'){
        notification['success']({
            message: 'Éxito',
            description: mensaje,
        })
    }else if(type === 'warning'){
        notification['warning']({
            message: 'Advertencia',
            description: mensaje,
        })
    }else if(type === 'error'){
        notification['error']({
            message: 'Error',
            description: mensaje,
        })
    }
}

export const LoginReducer = async (data, dispatch) => {
    await fetch(config.api_url+'log-in',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
            },
            body: JSON.stringify({
                req_usuario: data.user,
                req_password: data.password,
            }),
        }
    )
    .then(res => res.json())
    .then(async data => {
        if(data.response){
            localStorage.setItem('usutoken', data.data.usutoken)
            dispatch(storeDataUser(data.data))
            notificacionServidor('success', data.message)
        }else{
            dispatch(storeDataUser(null))
            notificacionServidor('warning', data.message)
        }
    }).catch((error)=> {
        console.log(error)
    })
}

export const ValidateUserServices = async (dispatch) => {
    if(localStorage.getItem('usutoken')){
        await fetch(config.api_url+'validate-user',
            {
                mode:'cors',
                method: 'POST',
                headers: {
                    'Accept' : 'application/json',
                    'Content-type' : 'application/json',
                    'usutoken': localStorage.getItem('usutoken'),
                },
            }
        )
        .then(res => res.json())
        .then(async data => {
            if(data.response){
                localStorage.setItem('usutoken', data.data.usutoken)
                dispatch(storeDataUser(data.data))
                // notificacionServidor('success', data.message)
            }else{
                LogOutReducer(dispatch)
                notificacionServidor('warning', data.message)
            }
        }).catch((error)=> {
            console.log(error)
        })
    }else{
        LogOutReducer(dispatch)
    }
}

export const LogOutReducer = (dispatch) => {
    dispatch(storeDataUser(null))
    localStorage.clear()

    return true
}