import { createSlice } from "@reduxjs/toolkit"
import { 
    ChangeFiltersBaselineReducer, 
    GenerateAmountInitialBaselineReducer, 
    GenerateFiltersBaselineReducer, 
    GenerateTotalMonthsBaselineReducer, 
    GenerateTotalNivPricesBaselineReducer, 
    ModifyGsuTonBaselineReducer, 
    UpdateMixBaselineReducer,
    ChangeFiltersDataBaseline
} from "../Actions/Baseline"
import { version } from "react"

export const Baseline = createSlice({
    name : 'baseline',
    initialState : {
        rex_data_baseline                   : [],
        rex_data_columns_baseline           : [],
        rex_names_version_baseline          : [],
        rex_version_selected_baseline       : {name :null, version:null},
        rex_input_version_baseline          : [],
        rex_loading_saving_gsu              : false,
        rex_total_data_baseline             : null,
        rex_create_version_baseline         : false,
        rex_filter_data_baseline            : {},
        rex_data_metrics                    : [],
        rex_data_total_volume               : [],
        rex_ids_filter                      : [],
        rex_history_gsu                     : [],
        rex_history_id_gsu                  : [],
        rex_cambios_pre_version             : [],
        rex_page_data_baseline              : 1,
        rex_data_baseline_clone             : [],
        rex_filter_active : false,
        rex_data_filter_selected_baseline            : {
            mdcgba1         : '',
            mdcgba2         : '',
            mdcfcstgroupcode: '',
            mdpsector       : '',
            mdpcategoria    : '',
            mdpsubcategoria : '',
            // mdpmarca        : '',
            mdpformato      : '',
            mpdtier         : '',
            mdpmetrajetalla : '',
            // mdpresumen      : ''
        },
        rex_data_filter_baseline   : {},
        rex_data_total_months               : {
            total_gsu_jun : 0,
            total_gsu_jul : 0,
            total_gsu_ago : 0,
            total_gsu_sep : 0,
            total_gsu_oct : 0,
            total_gsu_nov : 0,
            total_gsu_dic : 0,
            
            sub_total_gsu_jun : 0,
            sub_total_gsu_jul : 0,
            sub_total_gsu_ago : 0,
            sub_total_gsu_sep : 0,
            sub_total_gsu_oct : 0,
            sub_total_gsu_nov : 0,
            sub_total_gsu_dic : 0,

            
            total_cases_jun : 0,
            total_cases_jul : 0,
            total_cases_ago : 0,
            total_cases_sep : 0,
            total_cases_oct : 0,
            total_cases_nov : 0,
            total_cases_dic : 0,

            total_nivpx_jun : 0,
            total_nivpx_jul : 0,
            total_nivpx_ago : 0,
            total_nivpx_sep : 0,
            total_nivpx_oct : 0,
            total_nivpx_nov : 0,
            total_nivpx_dic : 0,


            total_nivlc_jun : 0,
            total_nivlc_jul : 0,
            total_nivlc_ago : 0,
            total_nivlc_sep : 0,
            total_nivlc_oct : 0,
            total_nivlc_nov : 0,
            total_nivlc_dic : 0,
            total_netpx_jun : 0,
            total_netpx_jul : 0,
            total_netpx_ago : 0,
            total_netpx_sep : 0,
            total_netpx_oct : 0,
            total_netpx_nov : 0,
            total_netpx_dic : 0,
            total_netlc_jun : 0,
            total_netlc_jul : 0,
            total_netlc_ago : 0,
            total_netlc_sep : 0,
            total_netlc_oct : 0,
            total_netlc_nov : 0,
            total_netlc_dic : 0,
        },
        rex_data_version: [],
        rex_data_version_selected: {},
        rex_data_mdp: [],
        rex_info_status_dashboard : {
            code : "InitialStatus",
            // code : "InitialStatus",
            status : 3,
            description : "Status inicial del Dashboard"
            // code : "Idle",
            // status : 0,
            // description : "El dashboard se encuentra actualizado"
        },
        rex_data_mdc: [],
        rex_data_sku: [],
        rex_data_sector: [],
        rex_data_categoria: [],
        rex_data_marca: [],
        rex_modal_new_version: false,
        rex_file_paths: [],
        rex_data_new_versions: [],
        rex_total_metrics_baseline : {},
        rex_gba1_mdc: [],
        rex_gba2_mdc: [],
        rex_fc_grp_cod_mdc: [],
        rex_sector_mdp: [],
        rex_categoria_mdp: [],
        rex_subcategoria_mdp: [],
        rex_marca_mdp: [],
        rex_metraje_talla_mdp: [],
        rex_formato_mdp: [],
        rex_tier_mdp: [],
        rex_resumen_mdp: [],
    },
    reducers : {
        storeNameVersionBaseline: (state, action) => {
            state.rex_names_version_baseline = action.payload
        },
        restartVersionSelectedBaseline: (state, action) => {
            let info = state.rex_version_selected_baseline
            info = {
                ...info,
                name : null,
                version : null
            }
            state.rex_version_selected_baseline = info
        },
        storeVersionSelectedBaseline: (state, action) => {
            let info = state.rex_version_selected_baseline
            const { type, name, version } = action.payload
            if(type == "name"){
                info = {
                    ...info,
                    name : name
                }
            }else{
                info = {
                    ...info,
                    version : version
                }
            }

            state.rex_version_selected_baseline = info
        },
        storeInputVersionBaseline: (state, action) => {
            state.rex_input_version_baseline = action.payload
        },
        addInputVersionBaseline: (state, action) => {

            let dataVersion = state.rex_names_version_baseline
            const nameVersion = action.payload

            let newVersion = [{
                value   : "v1",
                label   : "v1"
            }]
            dataVersion.push({
                value   : nameVersion,
                label   : nameVersion,
                versions: newVersion
            })

            state.rex_names_version_baseline = dataVersion
            state.rex_input_version_baseline = newVersion
        },
        storeFiltersDataBaseline: (state, action) => {
            let { value, filter, type } = action.payload
            let data_filter = {...state.rex_filter_data_baseline}

            let { data } = ChangeFiltersDataBaseline( data_filter, value, filter, type )

            state.rex_filter_data_baseline = data
        },
        storeTotalMetricsBaseline: (state, action) => {
            state.rex_total_metrics_baseline = action.payload
        },
        storeLoadingSavingGsuBaseline: (state, action) => {
            console.log("actualiza a : " + action.payload)
            state.rex_loading_saving_gsu = action.payload
        },
        updateStatusCreateVersionBaseline: (state, action) => {
            state.rex_create_version_baseline = action.payload
        },
        storeDataColumnsBaseline: (state, action) => {
            state.rex_data_columns_baseline = action.payload
        },
        storeDataVolumeBaseline: (state, action) => {
            state.rex_data_total_volume = action.payload
        },
        UpdateGsuHistoryBaseline: (state, action) => {
            state.rex_history_gsu = action.payload
        },
        UpdateGsuHistoryIdBaseline: (state, action) => {
            state.rex_history_id_gsu = action.payload
        },
        UpdateIdsFiltersBaseline: (state, action) => {
            state.rex_ids_filter = action.payload
        },
        storeDataBaseline: (state, action) => {
          
            const { prevData, metrics } = action.payload

            // rex_data_total_volume => volumen total todos registros
            
            let dataVolumeTotal = state.rex_data_total_volume
            let ids             = state.rex_ids_filter
            let dataTotal       = state.rex_data_columns_baseline
                    
            const idsSet = new Set(ids);
            dataTotal.forEach(dt => {

                prevData.forEach(bsl => {
                    const dataBsl = dataVolumeTotal.find(dvt => dvt.baseline.bslid == bsl.bslid && dvt.fecfecha.fecmonth == dt.fecmonth && dvt.fecfecha.fecyear == dt.fecyear)
                    if(bsl['prices'].length > 0){
                        const idxPr = bsl['prices'].findIndex(pr => pr.fecfecha.fecmonth == dt.fecmonth && pr.fecfecha.fecyear == dt.fecyear)
                        if(idxPr != -1){
                            if(bsl['prices'][idxPr]['privolumen']){
                                const fc = bsl['prices'][idxPr]['privolumen']/bsl['prices'][idxPr]['pricajas']
                                if(!isNaN(fc)){
                                    bsl['prices'][idxPr]['privolumen'] = dataBsl.privolumen
                                    bsl['prices'][idxPr]['pricajas'] = dataBsl.privolumen/fc
                                    bsl['prices'][idxPr]['prinivlc'] = (dataBsl.privolumen/fc)*bsl['prices'][idxPr]['prinetprice']
                                }
                            }
                        }


                    }
                })

                const totalVolumePos = dataVolumeTotal.reduce((sum, item) => {
                    if (
                        item.fecfecha.fecmonth === dt.fecmonth &&
                        item.fecfecha.fecyear === dt.fecyear &&
                        idsSet.has(item.baseline.bslid)
                    ) {
                        return sum + Number(item.privolumen);
                    }
                    return sum;
                }, 0);

                const totalBoxPos = dataVolumeTotal.reduce((sum, item) => {
                    if (
                        item.fecfecha.fecmonth === dt.fecmonth &&
                        item.fecfecha.fecyear === dt.fecyear &&
                        idsSet.has(item.baseline.bslid)
                    ) {
                        return sum + Number(item.pricajas);
                    }
                    return sum;
                }, 0);

                const totalNivLcPos = dataVolumeTotal.reduce((sum, item) => {
                    if (
                        item.fecfecha.fecmonth === dt.fecmonth &&
                        item.fecfecha.fecyear === dt.fecyear &&
                        idsSet.has(item.baseline.bslid)
                    ) {
                        return sum + Number(item.prinivlc);
                    }
                    return sum;
                }, 0);

                const idxCurrentMonth = dataTotal.findIndex(dtv => dtv.fecmonth == dt.fecmonth && dtv.fecyear == dt.fecyear)
                dataTotal[idxCurrentMonth]['data']['sumvolumen'] = totalVolumePos
                dataTotal[idxCurrentMonth]['data']['sumcajas'] = totalBoxPos
                dataTotal[idxCurrentMonth]['data']['sumnivlc'] = totalNivLcPos
            })
            
            state.rex_data_baseline = prevData
            state.rex_data_columns_baseline = dataTotal

        },
        storeDataBaselineClone: (state, action) => {
            state.rex_data_baseline_clone = action.payload
        },
        storeTotalDataBaseline: (state, action) => {
            state.rex_total_data_baseline = action.payload
        },
        storePageDataBaseline: (state, action) => {
            state.rex_page_data_baseline = action.payload
        },
        storeDataVersion: (state, action) => {
            state.rex_data_version = action.payload
        },
        storeDataMetricsBaseline: (state, action) => {
            state.rex_data_metrics = action.payload
        },
        storeDataVersionSelected: (state, action) => {
            state.rex_data_version_selected = action.payload
        },
        storeDataSku: (state, action) => {
            state.rex_data_sku = action.payload
        },
        storeDataMdp: (state, action) => {
            state.rex_data_mdp = action.payload
        },
        storeDataMdc: (state, action) => {
            state.rex_data_mdc = action.payload
        },
        storeDataSector: (state, action) => {
            state.rex_data_sector = action.payload
        },
        storeDataCategoria: (state, action) => {
            state.rex_data_categoria = action.payload
        },
        storeDataMarca: (state, action) => {
            state.rex_data_marca = action.payload
        },
        storeStatusDashboard: (state, action) => {
            state.rex_info_status_dashboard = action.payload
        },
        storeFiltersBaseline: (state, action) => {
            let data = action.payload
            let dataFilters = GenerateFiltersBaselineReducer(data)
            state.rex_data_filter_baseline = dataFilters
        },
        modifyValueMixBaseline: (state, action) => {
            const { id, value } = action.payload

            let dataBaseline = state.rex_data_baseline
            const dataUpdate = UpdateMixBaselineReducer(dataBaseline, id, value)
            state.rex_data_baseline = dataUpdate        
        },
        changeFilterBaseline: (state, action) => {
            let { value, filter } = action.payload
            let dataBaseline = state.rex_data_baseline_clone
            let dataPrevMonth = state.rex_data_total_months
            let filters_selected = state.rex_data_filter_selected_baseline

            let valueSelect = value ? value : ''
            filters_selected = {
                ...filters_selected,
                [filter] : valueSelect
            }
            let { dataFiltered, filterActive, dataMonths } = ChangeFiltersBaselineReducer(dataBaseline, filters_selected, dataPrevMonth)

            state.rex_data_baseline = dataFiltered

            state.rex_data_filter_selected_baseline = filters_selected

            state.rex_filter_active = filterActive

            state.rex_data_total_months = dataMonths
        },













        updateTotalGSU : (state, action) => {

            const { month, year, value } = action.payload

            let ids             = state.rex_ids_filter
            let dataTotal       = state.rex_data_columns_baseline
            let dataBaseline    = state.rex_data_baseline
            let dataVolumeTotal = state.rex_data_total_volume

            const sumTotalPrev = dataVolumeTotal.filter(item =>
                item.fecfecha.fecmonth === month &&
                item.fecfecha.fecyear === year &&
                ids.includes(item.baseline.bslid)
            )

            const totalVolumePrev = sumTotalPrev.reduce((sum, item) => Number(sum) + Number(item.privolumen), 0)
            const factor = value/totalVolumePrev

            const idsBaseSet = new Set(ids)

            dataVolumeTotal.forEach(item => {
                if (
                    item.fecfecha.fecmonth === month &&
                    item.fecfecha.fecyear === year &&
                    idsBaseSet.has(item.baseline.bslid)
                ) {
                    let fc = item.privolumen/item.pricajas
                    item.privolumen = item.privolumen * factor
                    if(!isNaN(fc)){
                        item.pricajas   = item.privolumen/fc
                        item.prinivlc   = item.pricajas*item.prinivprice
                    }
                }
            })

            dataBaseline.forEach(bsl => {
                const dataBsl = dataVolumeTotal.find(dvt => dvt.baseline.bslid == bsl.bslid && dvt.fecfecha.fecmonth == month && dvt.fecfecha.fecyear == year)
                const idxPr = bsl['prices'].findIndex(pr => pr.fecfecha.fecmonth == month && pr.fecfecha.fecyear == year)
                if(bsl['prices'][idxPr]['privolumen']){
                    const fc = bsl['prices'][idxPr]['privolumen']/bsl['prices'][idxPr]['pricajas']
                    if(!isNaN(fc)){
                        bsl['prices'][idxPr]['privolumen'] = dataBsl.privolumen
                        bsl['prices'][idxPr]['pricajas'] = dataBsl.privolumen/fc
                        bsl['prices'][idxPr]['prinivlc'] = (dataBsl.privolumen/fc)*bsl['prices'][idxPr]['prinetprice']
                    }
                }
            })

            const sumTotalPos = dataVolumeTotal.filter(item =>
                item.fecfecha.fecmonth === month &&
                item.fecfecha.fecyear === year &&
                ids.includes(item.baseline.bslid)
            )

            const totalVolumePos    = sumTotalPos.reduce((sum, item) => Number(sum) + Number(item.privolumen), 0)
            const totalBoxesPos     = sumTotalPos.reduce((sum, item) => Number(sum) + Number(item.pricajas), 0)
            const totalNivLcPos     = sumTotalPos.reduce((sum, item) => Number(sum) + Number(item.prinivlc), 0)

            const idxCurrentMonth = dataTotal.findIndex(dt => dt.fecmonth == month && dt.fecyear == year)
            dataTotal[idxCurrentMonth]['data']['sumvolumen']    = totalVolumePos
            dataTotal[idxCurrentMonth]['data']['sumcajas']      = totalBoxesPos
            dataTotal[idxCurrentMonth]['data']['sumnivlc']      = totalNivLcPos

            state.rex_data_baseline = dataBaseline
            state.rex_data_columns_baseline = dataTotal
        },




        updateSumTotalForecast : (state, action) => {
            const { month, value } = action.payload

            let filters_selected    = state.rex_data_filter_selected_baseline
            let dataBaseline        = state.rex_data_baseline
            let dataBaselineClone   = state.rex_data_baseline_clone
            let filterActive        = state.rex_filter_active
            let totalMonths        = state.rex_data_total_months


            let { newBaseline, newBaselineClone } = ModifyGsuTonBaselineReducer(filters_selected, dataBaseline, dataBaselineClone, month, value, filterActive, totalMonths)

            let dif = 0
            if(month == "06"){
                if(filterActive){
                    totalMonths = {
                        ...totalMonths,
                        sub_total_gsu_jun : value,
                        total_gsu_jun : parseFloat(totalMonths.total_gsu_jun) + (value - totalMonths.sub_total_gsu_jun),
                    }
                }else{
                    totalMonths = {
                        ...totalMonths,
                        total_gsu_jun : value,
                    }
                }
            }else if(month == "07"){
                if(filterActive){
                    totalMonths = {
                        ...totalMonths,
                        sub_total_gsu_jul : value,
                        total_gsu_jul : parseFloat(totalMonths.total_gsu_jul) + (value - totalMonths.sub_total_gsu_jul),
                    }
                }else{
                    totalMonths = {
                        ...totalMonths,
                        total_gsu_jul : value,
                    }
                }
            }else if(month == "08"){
                if(filterActive){
                    totalMonths = {
                        ...totalMonths,
                        sub_total_gsu_ago : value,
                        total_gsu_ago : parseFloat(totalMonths.total_gsu_ago) + (value - totalMonths.sub_total_gsu_ago),
                    }
                }else{
                    totalMonths = {
                        ...totalMonths,
                        total_gsu_ago : value,
                    }
                }
            }else if(month == "09"){
                if(filterActive){
                    totalMonths = {
                        ...totalMonths,
                        sub_total_gsu_sep : value,
                        total_gsu_sep : parseFloat(totalMonths.total_gsu_sep) + (value - totalMonths.sub_total_gsu_sep),
                    }
                }else{
                    totalMonths = {
                        ...totalMonths,
                        total_gsu_sep : value,
                    }
                }
            }else if(month == "10"){
                if(filterActive){
                    totalMonths = {
                        ...totalMonths,
                        sub_total_gsu_oct : value,
                        total_gsu_oct : parseFloat(totalMonths.total_gsu_oct) + (value - totalMonths.sub_total_gsu_oct),
                    }
                }else{
                    totalMonths = {
                        ...totalMonths,
                        total_gsu_ago : value,
                    }
                }
            }else if(month == "11"){
                if(filterActive){
                    totalMonths = {
                        ...totalMonths,
                        sub_total_gsu_nov : value,
                        total_gsu_nov : parseFloat(totalMonths.total_gsu_nov) + (value - totalMonths.sub_total_gsu_nov),
                    }
                }else{
                    totalMonths = {
                        ...totalMonths,
                        total_gsu_nov : value,
                    }
                }
            }else if(month == "12"){
                if(filterActive){
                    totalMonths = {
                        ...totalMonths,
                        sub_total_gsu_dic : value,
                        total_gsu_dic : parseFloat(totalMonths.total_gsu_nov) + (value - totalMonths.sub_total_gsu_nov),
                    }
                }else{
                    totalMonths = {
                        ...totalMonths,
                        total_gsu_dic : value,
                    }
                }
            }

            state.rex_data_baseline         = newBaseline
            state.rex_data_total_months     = totalMonths
        },
        storeTotalMonthsBaseline: (state, action) => {
            let data = action.payload
            let dataTotalMonths = GenerateTotalMonthsBaselineReducer(data)
            state.rex_data_total_months = dataTotalMonths
        },
        updateTotalForecastInitial: (state, action) => {
            const data_baseline = state.rex_data_baseline
            const { fcJun, fcJul, fcAgo, fcSep, fcOct, fcNov, fcDic, data } = GenerateAmountInitialBaselineReducer(data_baseline, action.payload)

            const rex_data_total_months = state.rex_data_total_months

            state.rex_data_total_months = {
                ...rex_data_total_months,
                total_gsu_jun : fcJun,
                total_gsu_jul : fcJul,
                total_gsu_ago : fcAgo,
                total_gsu_sep : fcSep,
                total_gsu_oct : fcOct,
                total_gsu_nov : fcNov,
                total_gsu_dic : fcDic,
            }

            state.rex_data_baseline = data
            state.rex_data_baseline_clone = data
        },

        updateTotalGsuJunBaseline: (state, action) => {
            const dataBaseline = state.rex_data_baseline
            const rex_data_total_months = state.rex_data_total_months
            const value = action.payload
            dataBaseline.forEach(dat => {
            });
            state.rex_data_total_months = {
                ...rex_data_total_months,
                total_gsu_jun : action.value
            }
        },

        updateTotalGsuJulBaseline: (state, action) => {
            let { ...rex_data_total_months } = state.rex_data_total_months
            let value = action.payload
            rex_data_total_months = {
                ...rex_data_total_months,
                total_gsu_jul : value
            }
            state.rex_data_total_months = rex_data_total_months
        },
        updateTotalGsuAgoBaseline: (state, action) => {
            let { ...rex_data_total_months } = state.rex_data_total_months
            let value = action.payload
            rex_data_total_months = {
                ...rex_data_total_months,
                total_gsu_ago : value
            }
            state.rex_data_total_months = rex_data_total_months
        },
        updateTotalGsuSepBaseline: (state, action) => {
            let { ...rex_data_total_months } = state.rex_data_total_months
            let value = action.payload
            rex_data_total_months = {
                ...rex_data_total_months,
                total_gsu_sep : value
            }
            state.rex_data_total_months = rex_data_total_months
        },
        updateTotalGsuOctBaseline: (state, action) => {
            let { ...rex_data_total_months } = state.rex_data_total_months
            let value = action.payload
            rex_data_total_months = {
                ...rex_data_total_months,
                total_gsu_oct : value
            }
            state.rex_data_total_months = rex_data_total_months
        },
        updateTotalGsuNovBaseline: (state, action) => {
            let { ...rex_data_total_months } = state.rex_data_total_months
            let value = action.payload
            rex_data_total_months = {
                ...rex_data_total_months,
                total_gsu_nov : value
            }
            state.rex_data_total_months = rex_data_total_months
        },
        updateTotalGsuDicBaseline: (state, action) => {
            let { ...rex_data_total_months } = state.rex_data_total_months
            let value = action.payload
            rex_data_total_months = {
                ...rex_data_total_months,
                total_gsu_dic : value
            }
            state.rex_data_total_months = rex_data_total_months
        },

        updateTotalNivPricesBaseline: (state, action) => {
            let dataBaseline = state.rex_data_baseline_clone
            const dataTotalNivPrice = GenerateTotalNivPricesBaselineReducer(dataBaseline)

            let rex_data_total_months = state.rex_data_total_months

            rex_data_total_months = {
                ...rex_data_total_months,
                total_gsu_jun : dataTotalNivPrice.total_gsu_jun,
                total_gsu_jul : dataTotalNivPrice.total_gsu_jul,
                total_gsu_ago : dataTotalNivPrice.total_gsu_ago,
                total_gsu_sep : dataTotalNivPrice.total_gsu_sep,
                total_gsu_oct : dataTotalNivPrice.total_gsu_oct,
                total_gsu_nov : dataTotalNivPrice.total_gsu_nov,
                total_gsu_dic : dataTotalNivPrice.total_gsu_dic,
            }

            state.rex_data_total_months = rex_data_total_months
        },
        changeModalNewVersion: (state, action) => {
            state.rex_modal_new_version = action.payload
        },
        changeFilePaths: (state, action) => {
            state.rex_file_paths = action.payload
        },
        changeDataNewVersions: (state, action) => {
            state.rex_data_new_versions = action.payload
        },
        changeGba1Mdc: (state, action) => {
            state.rex_gba1_mdc = action.payload
        },
        changeGba2Mdc: (state, action) => {
            state.rex_gba2_mdc = action.payload
        },
        changeFcGrpCodMdc: (state, action) => {
            state.rex_fc_grp_cod_mdc = action.payload
        },
        changeSectorMdp: (state, action) => {
            state.rex_sector_mdp = action.payload
        },
        changeCategoriaMdp: (state, action) => {
            state.rex_categoria_mdp = action.payload
        },
        changeSubcategoriaMdp: (state, action) => {
            state.rex_subcategoria_mdp = action.payload
        },
        changeMarcaMdp: (state, action) => {
            state.rex_marca_mdp = action.payload
        },
        changeTierMdp: (state, action) => {
            state.rex_tier_mdp = action.payload
        },
        changeMetrajeTallaMdp: (state, action) => {
            state.rex_metraje_talla_mdp = action.payload
        },
        changeFormatoMdp: (state, action) => {
            state.rex_formato_mdp = action.payload
        },
        changeResumenMdp: (state, action) => {
            state.rex_resumen_mdp = action.payload
        },
        changeDataFilterBaseline: (state, action) => {
            state.rex_data_filter_selected_baseline = action.payload
        },
    }
})

export default Baseline.reducer
export const { 
    addBaseline, 
    addNewProducts, 
    storeDataBaseline, 
    storeFiltersBaseline, 
    storeTotalMonthsBaseline,
    updateTotalGsuJunBaseline,
    updateTotalGsuJulBaseline,
    updateTotalGsuAgoBaseline,
    updateTotalGsuSepBaseline,
    updateTotalGsuOctBaseline,
    updateTotalGsuNovBaseline,
    updateTotalGsuDicBaseline,
    changeFilterBaseline,
    storeDataBaselineClone, 
    storeDataVersion,
    storePageDataBaseline,
    storeDataVersionSelected, 
    storeDataMdp,
    storeDataMdc,
    storeDataSku, 
    updateTotalForecastInitial,
    storeDataSector, 
    storeDataCategoria, 
    storeDataMarca,
    updateTotalNivPricesBaseline,
    modifyValueMixBaseline,
    updateSumTotalForecast,
    changeModalNewVersion,
    changeFilePaths,
    storeFiltersDataBaseline,
    storeTotalDataBaseline,
    storeDataMetricsBaseline,
    changeDataNewVersions,
    storeTotalMetricsBaseline,
    updateTotalGSU,
    changeGba1Mdc,
    changeGba2Mdc,
    changeFcGrpCodMdc,
    changeSectorMdp,
    changeCategoriaMdp,
    changeSubcategoriaMdp,
    changeMarcaMdp,
    changeTierMdp,
    changeMetrajeTallaMdp,
    changeFormatoMdp,
    changeResumenMdp,
    storeStatusDashboard,
    updateStatusCreateVersionBaseline,
    changeDataFilterBaseline,
    storeDataColumnsBaseline,
    UpdateGsuHistoryBaseline,
    UpdateIdsFiltersBaseline,
    storeDataVolumeBaseline,
    storeLoadingSavingGsuBaseline,
    storeNameVersionBaseline,
    storeInputVersionBaseline,
    addInputVersionBaseline,
    storeVersionSelectedBaseline,
    restartVersionSelectedBaseline,
} = Baseline.actions