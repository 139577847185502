import { createSlice } from "@reduxjs/toolkit"

export const Dashboard = createSlice({
    name : 'dashboard',
    initialState : {
        rex_data_dashboard: null,
        rex_link_dashboard: null,
        rex_state_dashboard: false,
    },
    reducers : {
        storeDataDashboard: (state, action) => {
            state.rex_data_dashboard = action.payload
        },
        storeLinkDashboard: (state, action) => {
            state.rex_link_dashboard = action.payload
        },
        storeStateDashboard: (state, action) => {
            state.rex_state_dashboard = action.payload
        },
    }
})

export default Dashboard.reducer
export const { 
    storeDataDashboard, 
    storeLinkDashboard, 
    storeStateDashboard 
} = Dashboard.actions