import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Routes, Route, useNavigate, Navigate, Link } from 'react-router-dom'
import { format } from 'date-fns'
import { useIdleTimer } from 'react-idle-timer'
import { Layout, Menu } from 'antd'
import FileUpload from './FileUpload/FileUpload'
import Baseline from './Data/Baseline'
import Deck from './Data/Deck'
import Logout from '../Components/Header/Logout'
import '../Styles/Routes/RoutesApp.css'
import socket from './../socket'
import { getStatusDashboardServices, getUpdateStatusServices } from '../Redux/Services/Baseline'
import { useQuery, useMutation } from '@tanstack/react-query'
import { LogOutReducer, ValidateUserServices } from '../Redux/Services/Header'
import AdminDeck from './Administrator/AdminDeck/AdminDeck'

const RoutesApp = () => {

	const { Header, Content, Footer } = Layout
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const [ firstCall, setFirstCall ] = useState(false)

	const { rex_data_user } = useSelector(state => state.user)

	const { 
        rex_info_status_dashboard
	} = useSelector(state => state.baseline)
	
	const current_year = format(new Date(), 'yyyy')

	const items = [
		{key: '/data/baseline', label: <Link to='/data/baseline'>Baseline</Link>},
		{key: '/data/deck', label: <Link to='/data/deck'>Deck</Link>},
		{key: '/file-upload', label: <Link to='/file-upload'>File Upload</Link>},
	]

	const items2 = [
		{key: '/administrador/deck', label: <Link to='/administrador/deck'>Link Deck</Link>},
		{key: '/data/deck', label: <Link to='/data/deck'>Administrador</Link>},
	]

	const validateUser = useQuery({
		queryKey: ['validateUser'],
		queryFn : () => ValidateUserServices(dispatch),
		retry: 1,
		refetchOnWindowFocus : false,
	})
	
	const getDataStatusServices = useMutation({
		mutationFn: (page) => getUpdateStatusServices(dispatch)
	})

	const getStatusClientDashboardServices = useMutation({
		mutationFn: (page) => getStatusDashboardServices(dispatch)
	})

	const getData = () => {
		if(rex_info_status_dashboard.code == "InitialStatus" || rex_info_status_dashboard.code == "OnExecution"){
			getDataStatusServices.mutate()
		}
	}

	const getDataClient = () => {
		getStatusClientDashboardServices.mutate()
	}

	const onIdle = async () => {
		const response = await LogOutReducer(dispatch)
		if(response){
			navigate('/login')
		}
    }

    const onActive = () => {}

    let timeout = 600000

	useIdleTimer({
        onIdle,
        onActive,
        timeout,
        throttle: 500
    })

	useEffect(() => {
	
		getData()
		const interval = setInterval(() => {
			getData()
		}, 60000)
	
		return () => clearInterval(interval);
	}, []);

	useEffect(() => {
		socket.on('get-status-dashboard', (data) => {
			getDataClient()
		})
	}, [])

	return (
		<Layout>
			<Header className="container-header" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
				<Menu
					className="header-menu"
					mode="horizontal"
					items={items}
					onClick={(e) => navigate(e.key)}
				/>
				<div style={{display: 'flex', columnGap: '5px'}}>
					{/* <Menu
						className="header-menu"
						mode="horizontal"
						items={items2}
						onClick={(e) => navigate(e.key)}
					/> */}
					<Logout />
				</div>
			</Header>
			<Content className="container-content" style={{ padding: '0 48px', height: 'calc(100vh - 88px)' }}>
				<Routes>
					<Route path="/" element={<Navigate replace to="/data/baseline" />} />
					<Route path="/login" element={<Navigate replace to="/data/baseline" />} />
					<Route exact path="/file-upload" element={<FileUpload/>}/>
					<Route exact path="/data/baseline" element={<Baseline/>}/>
					<Route exact path="/data/deck" element={<Deck/>}/>
					<Route exact path="/administrador/deck" element={<AdminDeck/>}/>
				</Routes>
			</Content>
			<Footer style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '48px', backgroundColor: '#ffffff', padding: 0 }}>Todos los derechos reservados a @Grow Analytics {current_year}</Footer>
		</Layout>
	)
}

export default RoutesApp