import React from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from "react-router-dom"
import { Col, Form, Input, Row, Button } from 'antd'
import { LoginReducer } from '../../Redux/Services/Header'
import './../../Styles/Routes/Login.css'
import { useMutation } from '@tanstack/react-query'

const Login = () => {
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const loginFinish = async (values) => {
		await loginMutation.mutate(values)
		navigate('/data/baseline')
	}

	const loginMutation = useMutation({
        mutationFn: (values) => LoginReducer(values, dispatch),
	})

	return (
		<Row>
			<Col xs={24} sm={24} md={24} lg={24}>
				<Row className='ContainerLogin'>
					<Col></Col>
					<Col span={6}>
						<div className='ContainerLogin__Text'>S&OP</div>
						<Form
							layout='vertical'
							className='FormLogin'
							onFinish={loginFinish}
						>
							<Form.Item
								className='FormLogin__Item'
								label='Direccion de correo electrónico:'
								name="user"
								required={[
									{
										required: true,
										message: 'El usuario es requerido',
									},
								]}
							>
								<Input
									style={{
										width: "100%",
										fontFamily: "Segoe UI",
										fontStyle: "normal",
										fontSize: "18px",
										lineHeight: "28px",
										borderRadius: "8px",
										height: '46px',
									}}
									placeholder='Direccion de correo electrónico'
								/>
							</Form.Item>
							<Form.Item
								className='FormLogin__Item'
								label='Contraseña:'
								name="password"
								required={[
									{
										required: true,
										message: 'La contraseña es requerido',
									},
								]}
							>
								<Input.Password
									style={{
										width: "100%",
										fontFamily: "Segoe UI",
										fontStyle: "normal",
										fontSize: "18px",
										lineHeight: "23px",
										borderRadius: "8px",
										height: '46px',
									}}
									placeholder='Contraseña'
								/>
							</Form.Item>
							<Form.Item
								className='FormLogin__Item'
							>
								<Button 
									type='primary' 
									htmlType="submit" 
									className='Button-Login'
									block
								>Iniciar Sesión</Button>
							</Form.Item>
						</Form>
					</Col>
					<Col></Col>
				</Row>
			</Col>
		</Row>
	)
}

export default Login