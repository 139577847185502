import config from "../../../config"
import { storeDataDashboard, storeLinkDashboard, storeStateDashboard } from "../../Reducers/Administrator/Dashboard"
import { notification } from 'antd'

const notificacionServidor = (type, mensaje) => {
    if(type === 'success'){
        notification['success']({
            message: 'Éxito',
            description: mensaje,
        })
    }else if(type === 'warning'){
        notification['warning']({
            message: 'Advertencia',
            description: mensaje,
        })
    }else if(type === 'error'){
        notification['error']({
            message: 'Error',
            description: mensaje,
        })
    }
}

export const getDashboardServices = async (dispatch) => {
    await fetch(config.api_url+`admin-dashboard/get-dashboard`, {
        mode:'cors',
        method: 'GET',
        headers: {
            'Accept' : 'application/json',
            'Content-type' : 'application/json',
            'usutoken': localStorage.getItem('usutoken'),
        },
    })
    .then(res => res.json())
    .then(async data => {
        if(data.response){
            if(data.data){
                dispatch(storeDataDashboard(data.data))
                dispatch(storeLinkDashboard(data.data.dasurl))
                dispatch(storeStateDashboard(data.data.dasvisualizacion))
                // notificacionServidor('success', data.message)
            }
        }else{
            notificacionServidor('error', data.message)
            throw new Error(`${data.message}`)
        }
    })
    .catch(error => {
        console.log(error)
        throw new Error('Los sentimos hubo un error en el servidor.')
    })
}

export const createUpdateDashboardServices = async (link_dashboard, state_dashboard) => {
    await fetch(config.api_url+`admin-dashboard/create-update-dashboard`, {
        mode:'cors',
        method: 'POST',
        headers: {
            'Accept' : 'application/json',
            'Content-type' : 'application/json',
            'usutoken': localStorage.getItem('usutoken'),
        },
        body: JSON.stringify({
            req_url_deck: link_dashboard,
            req_state: state_dashboard,
        })
    })
    .then(res => res.json())
    .then(async data => {
        if(data.response){
            notificacionServidor('success', data.message)
        }else{
            notificacionServidor('warning', data.message)
            throw new Error(`${data.message}`)
        }
    })
    .catch(error => {
        console.log(error)
        throw new Error('Los sentimos hubo un error en el servidor.')
    })
}