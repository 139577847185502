import { createSlice } from "@reduxjs/toolkit"

export const FileUpload = createSlice({
    name: 'fileupload',
    initialState: {
        rex_msgs_notificaciones : [],
        rex_data_forecast       : [],
        rex_uploading_file      : false,
    },
    reducers: {
        addNotifications: (state, action) => {
            let newNotify = state.rex_msgs_notificaciones
            newNotify = newNotify.concat(action.payload)
            state.rex_msgs_notificaciones = newNotify
        },
        setStateUploadingFile: (state, action) => {
            state.rex_uploading_file = action.payload
        },
        removeNotifications: (state, action) => {

            const index = action.payload
            let notifications = state.rex_msgs_notificaciones
            notifications.splice(index, 1)

            notifications.forEach((not, index) => {
                not['titulo'] = `Notificación N°${String(index + 1).padStart(2, '0')}`
            })
            state.rex_msgs_notificaciones = notifications
        },
        seeAllNotifications: (state, action) => {
            let notifications = state.rex_msgs_notificaciones
            notifications.forEach((not, index) => {
                not['isOpen'] = true
            })
            state.rex_msgs_notificaciones = notifications
        },
        newDataForecast: (state, action) => {
            state.rex_data_forecast = action.payload
        },
    }
})

export default FileUpload.reducer
export const { 
    addNotifications, 
    removeNotifications,
    seeAllNotifications,
    newDataForecast,
    setStateUploadingFile
} = FileUpload.actions