import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { Row, Col, Typography, Input, Button, Switch, Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { createUpdateDashboardServices, getDashboardServices } from '../../../Redux/Services/Administrator/Dashboard'
import { storeLinkDashboard, storeStateDashboard } from '../../../Redux/Reducers/Administrator/Dashboard'
import '../../../Styles/Routes/Administrator/AdminDeck/AdminDeck.css'

const AdminDeck = () => {
    const { Title } = Typography

    const { 
        rex_link_dashboard,
        rex_state_dashboard,
	} = useSelector(state => state.dashboard)

    const dispatch = useDispatch()
    const queryClient = useQueryClient()

    const dashboardData = useQuery({
		queryKey: ['dashboard'],
		queryFn : () => getDashboardServices(dispatch),
		retry: 1,
		refetchOnWindowFocus : false,
	})

    const mutateDashboard = useMutation({
		mutationFn: () => createUpdateDashboardServices(rex_link_dashboard, rex_state_dashboard),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['dashboard'] })
        }
	})

	return (
		<Row>
			<Col span={24}>
                <Title level={3}>Link del Power BI</Title>
            </Col>
            <Col span={8} style={{marginTop: '5px'}}>
                <Row gutter={[10]}>
                    <Col span={20}>
                        <Spin 
                            indicator={<LoadingOutlined spin />} 
                            spinning={dashboardData.isLoading}
                        >
                            <Input 
                                onChange={(e) => dispatch(storeLinkDashboard(e.target.value))}
                                value={rex_link_dashboard}
                            />
                        </Spin>
                    </Col>
                    <Col style={{margin: 'auto'}}>
                        <Switch 
                            checked={rex_state_dashboard}
                            onChange={(e) => dispatch(storeStateDashboard(e))}
                            loading={dashboardData.isLoading} 
                        />
                    </Col>
                </Row>
                <Button
                    className="Button__Link"
                    onClick={() => mutateDashboard.mutate()}
                >
                    Asignar Link
                </Button>
            </Col>
        </Row>
	)
}

export default AdminDeck